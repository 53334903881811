import React from "react"
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {DataProvider, componentMap} from "../contentful/data-provider";
import {Helmet} from "react-helmet";
import ogimage from "../images/ogimages/community.png";

export default function Community({data}) {
    const dataProvider = new DataProvider(data);
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Join The Community',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Join The Community',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Join The Community</title>
            </Helmet>
            <div className={'community-page'}>
                {dataProvider.getCurrentPageSections().map((section, index) => {
                    return <div key={index}>{dataProvider.renderPageSection(section)}</div>;
                })}
            </div>
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulPage(id: {eq: "c519b31e-40de-561e-b7ce-9ebf222ea45d"}) {
            ... CurrentPageDetails
        }
        allContentfulEntry {
            edges {
                node {
                    ... AllEntryDetails
                }
            }
        }
        allContentfulEventPost(sort: {fields: date, order: ASC}) {
            nodes {
                image {
                    url
                    title
                }
                title
                jointEventUrl
                slug
                excerpt
                body {
                    raw
                }
                id
                date(formatString: "MMMM DD, YYYY")
            }
        }
    }
`;
